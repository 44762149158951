<template>
  <div class="group_radio">
    <RadioItem
      v-for="(item, index) in dataList"
      :id="item.optionNum"
      :key="index"
      :class="itemClass"
      :text="item.selection"
      :name="name"
      :isDisabled="isDisabled || item.isDisabled"
      :selectedId.sync="selectedIdSync"
      @onChange="onChange(item)"
    />
  </div>
</template>

<script>
import RadioItem from "@/components/common/radio/RadioItem.vue";
export default {
  name: "PerformanceRadio",
  components: {
    RadioItem,
  },
  props: {
    dataList: Array,
    itemClass: String,
    name: String,
    // selectedId: String,
    selectedId: undefined,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    qIdx: Number,
  },
  computed: {
    selectedIdSync: {
      get() {
        return this.selectedId;
      },
      set(value) {
        this.$emit("update:selectedId", value);
      },
    },
  },
  methods: {
    onChange(item) {
      this.$emit("onChange", this.qIdx, item);
    },
  },
};
</script>
<style scoped>
.group_radio {
  display: inline-block;
}
.group_radio .radio_comm {
  padding: 7px 0;
}
</style>
